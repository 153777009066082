import gql from 'graphql-tag'


export const LIST_CATEGORY = (templateType) => gql`query LIST_CATEGORY ($accountType: String!) {
  categories: listAccount${templateType}Category (accountType: $accountType) {
    id type code name
  }
}`

export const DETAIL_CATEGORY = (templateType) => gql`query DETAIL_CATEGORY ($accountType: String!, $categoryId: Int!) {
  category: detailAccount${templateType}Category (accountType: $accountType, categoryId: $categoryId) {
    id type code name
  }
}`

export const CREATE_CATEGORY = (templateType) => gql`mutation CREATE_CATEGORY ($accountType: String!, $input: AccountCategoryInput!) {
  createCategory: createAccount${templateType}Category (accountType: $accountType, input: $input) {
    id type code name
  }
}`

export const UPDATE_CATEGORY = (templateType) => gql`mutation UPDATE_CATEGORY ($accountType: String!, $categoryId: Int!, $input: AccountCategoryInput!) {
  updateCategory: updateAccount${templateType}Category (accountType: $accountType, categoryId: $categoryId, input: $input) {
    id type code name
  }
}`

export const DESTROY_CATEGORY = (templateType) => gql`mutation DESTROY_CATEGORY ($accountType: String!, $categoryId: Int!) {
  destroyCategory: destroyAccount${templateType}Category (accountType: $accountType, categoryId: $categoryId) {
    id
  }
}`
