import gql from 'graphql-tag'

export const LIST_IMPORT_VALIDATION = (templateType) => gql`query LIST_IMPORT_VALIDATION ($accountType: String!, $input: [Account${templateType}ImportInput]!) {
  result: listAccount${templateType}ImportValidation (accountType: $accountType, input: $input) {
    duplicates {
      code name parent
    }
    parents {
      code name parent
    }
    adds {
      code name
      parentId parent {id code name}
    }
    updates {
      id code name
      parentId parent {id code name}
    }
  }
}`
