import gql from 'graphql-tag'


const listResponse = `
  id code name
  parentId children {
    id code name parentId
  }
`

export const LIST_ACCOUNT = (templateType) => gql`query LIST_ACCOUNT ($accountType: String!, $q: FilterInput) {
  accounts: listAccount${templateType} (accountType: $accountType, q: $q) {${listResponse}}
}`

const detailResponse = `
  id code name parentId
  categories {id name}
`

export const DETAIL_ACCOUNT = (templateType) => gql`query DETAIL_ACCOUNT ($accountType: String!, $accountId: Int!) {
  account: detailAccount${templateType} (accountType: $accountType, accountId: $accountId) {${detailResponse}}
}`

export const CREATE_ACCOUNT = (templateType) => gql`mutation CREATE_ACCOUNT ($accountType: String!, $input: Account${templateType}Input!) {
  createAccount: createAccount${templateType} (accountType: $accountType, input: $input) {${detailResponse}}
}`

export const UPDATE_ACCOUNT = (templateType) => gql`mutation UPDATE_ACCOUNT ($accountType: String!, $accountId: Int!, $input: Account${templateType}Input!) {
  updateAccount: updateAccount${templateType} (accountType: $accountType, accountId: $accountId, input: $input) {${detailResponse}}
}`

export const DESTROY_ACCOUNT = (templateType) => gql`mutation DESTROY_ACCOUNT ($accountType: String!, $accountId: Int!) {
  destroyAccount: destroyAccount${templateType} (accountType: $accountType, accountId: $accountId) {id}
}`

export const DROPDOWN_PARENTS = (templateType) => gql`query DROPDOWN_PARENTS ($accountType: String!, $q: FilterInput) {
  items: listAccount${templateType}DropdownParent (accountType: $accountType, q: $q) {
    id code name
  }
}`

export const LIST_TRIAL_BALANCE = (templateType) => gql`query LIST_TRIAL_BALANCE ($accountType: String!, $startDate: Date, $endDate: Date) {
  items: listAccount${templateType}TrialBalance (accountType: $accountType, startDate: $startDate, endDate: $endDate) {
    id code name parentId
    amount amountDebit amountCredit
    total totalDebit totalCredit
    docConfigs ledgers
  }
}`
